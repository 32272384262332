// material
import { DraggableProvided } from 'react-beautiful-dnd'

import { Box, TableCell, useTheme } from '@mui/material'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import ExpandMore from './ExpandMore'
import TableCellCustom from './TableCellCustom'
// component
import { TableCellHead } from './styled'
import { Column, GroupColumn } from './types'

interface TableHeadProps {
  columns: Column[]
  groupColumns?: GroupColumn[]

  isPureHeader?: boolean
  isShowTitle?: boolean

  expandable?: boolean
  isGroupExpanded?: boolean
  handleOnToggleExpand?: () => void
  arrowIndex?: number
  draggableProvided?: DraggableProvided
}

const TableHeadComponent = ({
  columns,
  groupColumns,

  isPureHeader = false,
  isShowTitle = true,

  expandable = false,
  isGroupExpanded = true,
  handleOnToggleExpand,
  arrowIndex = columns.length - 1,
  draggableProvided,
}: TableHeadProps) => {
  const theme = useTheme()
  const borderStyle = '1px solid #E5E7EB'

  return (
    <TableHead>
      {groupColumns &&
        groupColumns.map((row) => (
          <TableRow key={row.row}>
            {row.columns.map((column, idx) => (
              <TableCell
                key={`${column.id}-${idx}`}
                colSpan={column.colSpan}
                align={column.align || 'center'}
                width={column.width}
                sx={{
                  '&:first-of-type': {
                    boxShadow: 'none',
                    paddingLeft: theme.spacing(column?.pl ?? 2),
                    '&.MuiTableCell-head': {
                      borderLeft: borderStyle,
                      borderTopLeftRadius: groupColumns ? theme.shape.borderRadius : 0,
                      borderBottomLeftRadius: 0,
                    },
                  },
                  '&:last-of-type': {
                    boxShadow: 'none',
                    '&.MuiTableCell-head': {
                      borderRight: borderStyle,
                      borderTopRightRadius: groupColumns ? theme.shape.borderRadius : 0,
                      borderBottomRightRadius: 0,
                    },
                  },
                }}
              >
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        ))}

      <TableRow
        ref={draggableProvided?.innerRef}
        {...draggableProvided?.draggableProps}
        {...draggableProvided?.dragHandleProps}
      >
        {columns.map((column, index) => (
          <TableCellCustom
            key={column.id}
            column={column}
            groupColumns={groupColumns}
            isPureHeader={isPureHeader}
            isGroupExpanded={isGroupExpanded}
          >
            <Box
              display={index === arrowIndex ? 'flex' : 'block'}
              alignItems={index === arrowIndex ? 'center' : 'flex-start'}
              justifyContent={column.align === 'center' ? 'center' : 'unset'}
            >
              {isShowTitle && (
                <Typography variant="h5" sx={{ '&.MuiTypography-h5': { fontSize: '14px' } }}>
                  {column.title}
                </Typography>
              )}
              {index === arrowIndex && !isPureHeader && expandable && (
                <ExpandMore
                  isGroupExpanded={isGroupExpanded}
                  handleOnToggleExpand={handleOnToggleExpand}
                />
              )}
            </Box>
          </TableCellCustom>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default TableHeadComponent
