export const USER_INFO_KEY = ['user']
export const FARMS_KEY = ['farms']
export const MANAGERS_KEY = ['managers']
export const REQUESTED_EMPLOYEE_KEY = ['requested-employee']
export const EMPLOYEES_KEY = ['employees']
export const getPondsKey = (farmId?: string) => ['ponds', farmId]
export const POND_CARETAKERS = ['pond-caretakers']
export const POND_TYPES_KEY = ['pond-types']
export const FOOD_BRANDS_KEY = ['food-brands']
export const SHRIMP_BRANDS_KEY = ['shrimp-brands']
export const getShrimpTypesKey = (brand: number) => ['shrimp-types', brand]
export const getCycleKey = (cycleId: string) => ['cycle', cycleId]
export const getCyclesKey = (pondId?: string) => ['cycles', pondId]
export const getMealsKey = (date: string, cycleId: string) => ['meals', date, cycleId]
export const FOOD_SIZES_KEY = ['food-sizes']
export const FEEDING_TYPES_KEY = ['feeding-types']
export const getFoodLineChartKey = (cycleId: string) => ['food-line-chart', cycleId]
export const getFoodsKey = ({
  cycleId,
  date,
  feedingType,
}: {
  cycleId: string
  date?: Date
  feedingType?: number
}) => ['foods', cycleId, date, feedingType]
export const getWaterQualitiesKey = (cycleId: string, date?: Date | string) => [
  'water-quality',
  cycleId,
  date,
]
export const getWaterMineralsKey = (cycleId: string, date?: Date | string) => [
  'water-mineral',
  cycleId,
  date,
]
export const getWaterBacteriasKey = (cycleId: string, date?: Date | string) => [
  'water-bacteria',
  cycleId,
  date,
]
export const getWaterQualityChartKey = (cycleId: string) => ['water-quality-chart', cycleId]
export const getWaterMineralChartKey = (cycleId: string) => ['water-mineral-chart', cycleId]
export const getWaterBacteriaChartKey = (cycleId: string) => ['water-bacteria-chart', cycleId]
export const getDeadShrimpsKey = (cycleId: string, date?: Date | string) => [
  'dead-shrimp',
  cycleId,
  date,
]
export const getDeadShrimpChartKey = (cycleId: string) => ['dead-shrimp-chart', cycleId]
export const getGrowthShrimpChartKey = (cycleId: string) => ['growth-shrimp-chart', cycleId]
export const getGrowthShrimpsKey = (cycleId: string, date?: Date | string) => [
  'growth-shrimps',
  cycleId,
  date,
]
export const getMedicinesKey = (cycleId: string, date?: Date | string) => [
  'medicines',
  cycleId,
  date,
]
export const getLatestMedicinePriceKey = (name: string) => ['medicine-price', name]
export const getMedicineNameKey = (farmId: string) => ['medicine-names', farmId]
export const getMedicinePieChartkey = (cycleId: string) => ['medicine-pie-chart', cycleId]
export const getLatestFoodPriceKey = (cycleId: string, foodSizeId: number | null) => [
  'food-price',
  cycleId,
  foodSizeId,
]
export const getPondDashboardKey = (pondId: string, cycleId: string) => [
  'pond-dashboard',
  pondId,
  cycleId,
]
export const getPondInfoKey = (cycleId: string) => ['pond-info', cycleId]
export const getShrimpSalesKey = (cycleId: string, date: Date | undefined) => [
  'shrimpSales',
  cycleId,
  date,
]
export const getCostKey = (cycleId: string | null) => ['cost', cycleId]
export const UNIT = ['unit']
export const getShrimpSalesSummaryKey = (cycleId: string) => ['getShrimpSalesSummaryKey', cycleId]
export const getFoodBrandsKey = (cycleId: string) => ['food-brands', cycleId]
export const LATEST_FOOD_BRAND = ['latest-food-brand']
export const LATEST_FOOD_SIZE = ['latest-food-size']
export const getFoodSizeKey = (foodBrandId: number | null) => ['food-size', foodBrandId]
export const getShrimpPriceKey = (startDate: string, endDate: string, regionId: number | null) => [
  'shrimp-price',
  startDate,
  endDate,
  regionId,
]
