// routes
import { Role } from 'modules/user/constants'
import { PATH_DASHBOARD } from 'routes/paths'

import { NavItemProps } from 'components/NavSection'
// components
import SvgIconStyle from 'components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
)

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  home: getIcon('ic_home'),
  setting: getIcon('ic_settings'),
  exit: getIcon('ic_reply'),
  user_group: getIcon('ic_user_group'),
  receipt: getIcon('ic_receipt'),
  // blog: getIcon('ic_blog'),
  // cart: getIcon('ic_cart'),
  // chat: getIcon('ic_chat'),
  // mail: getIcon('ic_mail'),
  // user: getIcon('ic_user'),
  // calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  // analytics: getIcon('ic_analytics'),
  // kanban: getIcon('ic_kanban')
}

const sidebarConfig = (role?: Role) => {
  let sidebarItems: NavItemProps[] | [] = []

  if (role === Role.PrimaryOwner || role === Role.Admin) {
    sidebarItems = [
      { title: 'ฟาร์มทั้งหมด', path: PATH_DASHBOARD.farms.root, icon: ICONS.home },
      { title: 'ราคากุ้งวันนี้', path: PATH_DASHBOARD.shrimpPrice.root, icon: ICONS.ecommerce },
    ]
  }

  return [
    {
      subheader: 'การจัดการ',
      items: sidebarItems,
    },
  ]
}

export default sidebarConfig
