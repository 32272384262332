import { useTranslation } from 'react-i18next'

// material
import Skeleton from '@mui/material/Skeleton'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// components
import { Column } from './types'

export interface BaseTableBodyProps {
  columns: Column[]
  isLoading?: boolean
}

interface TableBodyProps extends BaseTableBodyProps {
  rows: any[]
}

const TableBodyComponent = ({ columns, rows, isLoading }: TableBodyProps) => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  return (
    <TableBody>
      {rows.map((row, index) => (
        <TableRow key={`${row.date}-${index}`}>
          {columns.map((column, idx) => {
            const value = row[column.id]
            return (
              <TableCell
                key={`${row.date}-${index}-${idx}`}
                align={column.align || 'center'}
                colSpan={column.colSpan}
                rowSpan={column.rowSpan}
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {column.format ? (value ? column.format(value) : '-') : value}
              </TableCell>
            )
          })}
        </TableRow>
      ))}
      {(rows.length === 0 || isLoading) && (
        <TableRow>
          <TableCell colSpan={columns.length}>
            <Typography
              color={theme.palette.grey[500]}
              variant="body1"
              textAlign="center"
              padding={2}
            >
              {rows.length === 0 ? t('no.data') : <Skeleton width="100%" />}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  )
}

export default TableBodyComponent
