import { FACEBOOK, LINE_OA, TIKTOK, YOUTUBE } from 'common/constants/contact'
import { APP_STORE, PLAY_STORE } from 'common/constants/download'
import useMedia from 'common/hooks/useMedia'
import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Slider from 'react-slick'

import {
  Box,
  Divider,
  Grid,
  keyframes,
  Link,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { Icon } from '@iconify/react'

import BlurCircle from './BlurCircle'
import FeatureCard from './FeatureCard'
import Page from 'components/Page'

const LandingPage = () => {
  const { t } = useTranslation('landing')
  const theme = useTheme()
  const isMobile = useMedia('md')
  const [slideIndex, setSlideIndex] = useState(0)
  let sliderRef = useRef(null) as unknown as Slider | null

  const moveUpDown = keyframes`
    0%, 100% {
      top: ${isMobile ? '80%' : '65%'}
    }
    50% {
      top: ${isMobile ? '79%' : '64%'}
    }
  `

  const webFeatures = (
    <Grid container spacing={4} pb={10}>
      <Grid item xs={6}>
        <Stack spacing={10}>
          <Box height="100px" />
          <FeatureCard
            titleI18nKey={'pond.info'}
            icon="ant-design:info-circle-filled"
            descriptionI18nKey={'pond.info.description'}
          />
          <FeatureCard
            titleI18nKey="food"
            icon="mdi:fish-food"
            descriptionI18nKey="food.description"
          />
          <FeatureCard
            titleI18nKey="growth"
            icon="carbon:growth"
            descriptionI18nKey="growth.description"
          />
          <FeatureCard
            titleI18nKey="water.quality"
            icon="material-symbols:water-ph-rounded"
            descriptionI18nKey="water.quality.description"
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={10}>
          <FeatureCard
            titleI18nKey="dead.shrimp"
            icon="mdi:emoticon-dead"
            descriptionI18nKey="dead.shrimp.description"
          />
          <FeatureCard
            titleI18nKey="medicine"
            icon="mdi:medicine"
            descriptionI18nKey="medicine.description"
          />
          <FeatureCard
            titleI18nKey="cost"
            icon="tabler:coin-filled"
            descriptionI18nKey="cost.description"
          />
          <FeatureCard
            titleI18nKey="shrimp.sales"
            icon="fa6-solid:shrimp"
            descriptionI18nKey="shrimp.sales.description"
          />
        </Stack>
      </Grid>
    </Grid>
  )

  const mobileFeatures = (
    <Box height="110vh">
      <Slider
        slidesToShow={1}
        slidesToScroll={1}
        infinite={true}
        ref={(slider) => {
          sliderRef = slider
        }}
        beforeChange={(_current, next) => setSlideIndex(next)}
      >
        <Box>
          <Grid container p={4} spacing={2}>
            <Grid item xs={6}>
              <FeatureCard
                titleI18nKey={'pond.info'}
                icon="ant-design:info-circle-filled"
                descriptionI18nKey={'pond.info.description'}
              />
              <FeatureCard
                titleI18nKey="food"
                icon="mdi:fish-food"
                descriptionI18nKey="food.description"
              />
            </Grid>
            <Grid item xs={6}>
              <FeatureCard
                titleI18nKey="growth"
                icon="carbon:growth"
                descriptionI18nKey="growth.description"
              />
              <FeatureCard
                titleI18nKey="water.quality"
                icon="material-symbols:water-ph-rounded"
                descriptionI18nKey="water.quality.description"
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container p={4} spacing={2}>
            <Grid item xs={6}>
              <FeatureCard
                titleI18nKey="dead.shrimp"
                icon="mdi:emoticon-dead"
                descriptionI18nKey="dead.shrimp.description"
              />
              <FeatureCard
                titleI18nKey="medicine"
                icon="mdi:medicine"
                descriptionI18nKey="medicine.description"
              />
            </Grid>
            <Grid item xs={6}>
              <FeatureCard
                titleI18nKey="cost"
                icon="tabler:coin-filled"
                descriptionI18nKey="cost.description"
              />
              <FeatureCard
                titleI18nKey="shrimp.sales"
                icon="fa6-solid:shrimp"
                descriptionI18nKey="shrimp.sales.description"
              />
            </Grid>
          </Grid>
        </Box>
      </Slider>
      <Stack direction="row" spacing={1} justifyContent="center">
        <Box
          sx={{
            width: '80px',
            height: '5px',
            background: slideIndex === 0 ? theme.palette.grey[300] : theme.palette.grey[200],
            cursor: 'pointer',
            borderRadius: `${theme.shape.borderRadius}px`,
          }}
          onClick={() => sliderRef?.slickGoTo(0)}
        />
        <Box
          sx={{
            width: '80px',
            height: '5px',
            background: slideIndex === 1 ? theme.palette.grey[300] : theme.palette.grey[200],
            cursor: 'pointer',
            borderRadius: `${theme.shape.borderRadius}px`,
          }}
          onClick={() => sliderRef?.slickGoTo(1)}
        />
      </Stack>
    </Box>
  )

  const socialMediaIconSize = isMobile ? '24px' : '40px'

  return (
    <Page>
      <Box
        sx={{
          background: theme.palette.grey[100],
          width: '100%',
          height: isMobile ? '120vh' : '70vh',
          position: 'relative',
        }}
      >
        <Stack
          sx={{
            pl: isMobile ? 4 : 10,
            pt: isMobile ? 4 : 0,
            height: '100%',
            justifyContent: isMobile ? 'start' : 'center',
          }}
        >
          <Typography variant="h3" component="h1" gutterBottom>
            <Typography
              component="span"
              sx={{ fontSize: isMobile ? '48px' : '96px' }}
              color={theme.palette.primary.dark}
            >
              Do
            </Typography>
            <Typography
              component="span"
              sx={{ fontSize: isMobile ? '48px' : '96px' }}
              color={theme.palette.primary.main}
            >
              Farm
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '36px' }} color={theme.palette.primary.main}>
            {t('cover.quote')}
          </Typography>
          <Typography variant="subtitle1">
            <Trans i18nKey={'landing:cover.description'} />
          </Typography>
          <Stack direction="row" spacing={2} mt={2}>
            <Link href={APP_STORE}>
              <img src="/static/landing/appstore.svg" alt="App Store" />
            </Link>
            <Link href={PLAY_STORE}>
              <img src="/static/landing/playstore.svg" alt="Google Play" />
            </Link>
          </Stack>
        </Stack>

        <BlurCircle $isMobile={isMobile} />
        <Box
          sx={{
            position: 'absolute',
            right: isMobile ? 0 : '5%',
            top: isMobile ? '50%' : '10%',
          }}
        >
          <img src="/static/landing/cover_macbook.svg" alt="macbook" />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: isMobile ? '50%' : '30%',
            top: isMobile ? '60%' : '30%',
            maxWidth: isMobile ? '128px' : 'auto',
          }}
        >
          <img src="/static/landing/cover_iphone.svg" alt="iphone" />
        </Box>
      </Box>

      <Box
        py={10}
        sx={{
          position: 'relative',
          boxShadow: '0 15px 20px rgba(0,0,0,0.2)',
        }}
      >
        <Box maxWidth="400px" mx="auto" textAlign="center">
          <Typography color={theme.palette.primary.main} variant="h4" component="h2" gutterBottom>
            {t('feature.title')}
          </Typography>
          <Divider sx={{ my: 4 }} />
          <Typography variant="h5" component="h3" gutterBottom>
            {t('feature.description')}
          </Typography>
        </Box>

        {isMobile ? mobileFeatures : webFeatures}

        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '65%',
            width: isMobile ? '300px' : '700px',
            height: isMobile ? '300px' : '700opx',
            transform: 'translate(-50%, -50%)',
            animation: `${moveUpDown} 2s ease-in-out infinite`,
          }}
        >
          <img src="/static/landing/feature_iphone.png" alt="feature-iphone" />
        </Box>
      </Box>

      <Box sx={{ background: theme.palette.grey[100], p: 4 }}>
        <Stack direction="row" justifyContent="center" spacing={2} mb={2}>
          <Box>
            <Link href={FACEBOOK} color="black" target="_blank" rel="noopener">
              <Icon
                icon="ic:baseline-facebook"
                width={socialMediaIconSize}
                height={socialMediaIconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={LINE_OA} color="black" target="_blank" rel="noopener">
              <Icon
                icon="fa-brands:line"
                width={socialMediaIconSize}
                height={socialMediaIconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={TIKTOK} color="black" target="_blank" rel="noopener">
              <Icon
                icon="ic:baseline-tiktok"
                width={socialMediaIconSize}
                height={socialMediaIconSize}
              />
            </Link>
          </Box>
          <Box>
            <Link href={YOUTUBE} color="black" target="_blank" rel="noopener">
              <Icon icon="mdi:youtube" width={socialMediaIconSize} height={socialMediaIconSize} />
            </Link>
          </Box>
        </Stack>
        <Stack alignItems="center" mb={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Icon icon="ic:baseline-email" width="16px" height="16px" />
            <Typography>dofarm.th@gmail.com</Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Icon icon="solar:phone-bold" width="16px" height="16px" />
            <Typography>+66 95-772-8989</Typography>
          </Stack>
        </Stack>
        <Typography variant="body2" color="textSecondary" align="center">
          {`© Copyright ${new Date().getFullYear()} TRIOFARM CO., LTD.`}
        </Typography>
      </Box>
    </Page>
  )
}

export default LandingPage
