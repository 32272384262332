import { formatThaiDateNumeric } from 'common/utils/date'
import { isSameDay } from 'date-fns'
import { useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, InputAdornment, Popover, TextField, useTheme } from '@mui/material'
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers'

import { Icon } from '@iconify/react'

export interface DateRange {
  startDate: Date
  endDate: Date
}

interface DateRangePickerProps {
  value: DateRange
  onChange: (dateRange: DateRange) => void
}

const DateRangePicker = ({ value, onChange }: DateRangePickerProps) => {
  const { t } = useTranslation('common')
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [startDate, setStartDate] = useState<Date | null>(value.startDate)
  const [endDate, setEndDate] = useState<Date | null>(value.endDate)
  const [tempStartDate, setTempStartDate] = useState<Date | null>(value.startDate)
  const [tempEndDate, setTempEndDate] = useState<Date | null>(value.endDate)

  const open = Boolean(anchorEl)

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setTempStartDate(startDate)
    setTempEndDate(endDate)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDateChange = (date: Date | null) => {
    if (!date) return

    if (!tempStartDate || (tempStartDate && tempEndDate)) {
      setTempStartDate(date)
      setTempEndDate(null)
    } else if (date < tempStartDate) {
      setTempStartDate(date)
    } else {
      setTempEndDate(date)
    }
  }

  const handleApply = () => {
    const today = new Date()
    setStartDate(tempStartDate)
    setEndDate(tempEndDate)
    onChange({ startDate: tempStartDate ?? today, endDate: tempEndDate ?? today })
    handleClose()
  }

  const displayValue =
    startDate && endDate
      ? `${formatThaiDateNumeric(startDate)} - ${formatThaiDateNumeric(endDate)}`
      : ''

  const CustomDay = (props: PickersDayProps<Date>) => {
    const { day, outsideCurrentMonth, ...other } = props
    const isStartDate = tempStartDate && isSameDay(day, tempStartDate)
    const isEndDate = tempEndDate && isSameDay(day, tempEndDate)
    const isInRange = tempStartDate && tempEndDate && day >= tempStartDate && day <= tempEndDate

    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        sx={{
          backgroundColor:
            isStartDate || isEndDate || isInRange ? theme.palette.primary.lighter : undefined,
        }}
      />
    )
  }

  return (
    <Box>
      <TextField
        label={t('select.date.range')}
        value={displayValue}
        onClick={handleOpen}
        aria-readonly
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon icon="majesticons:calendar" width={24} height={24} />
            </InputAdornment>
          ),
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box p={2}>
          <DateCalendar
            value={tempStartDate}
            onChange={handleDateChange}
            slots={{ day: CustomDay }}
          />
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleApply}>
              {t('confirm')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default DateRangePicker
