export const buildUrlWithParams = (
  url: string,
  params?: Record<string, any>
)  => {
  const query = [];

  if (!params) return url;

  for (const key in params) {
    query.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]));
  }
  return `${url}?${query.join("&")}`;
}