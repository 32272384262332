import { fNumber } from 'common/utils/formatNumber'

import { Box, Stack, Typography, useTheme } from '@mui/material'

import { Icon } from '@iconify/react'

interface PriceLabelProps {
  price?: number
  difference?: number
}

const PriceLabel = ({ price, difference }: PriceLabelProps) => {
  const theme = useTheme()
  if (!price)
    return (
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
        {'-'}
        <Box width="18px" />
      </Stack>
    )
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
      <Typography variant="subtitle2">{fNumber(price, { disabledDecimal: true })}</Typography>
      {difference ? (
        difference > 0 ? (
          <Typography color={theme.palette.primary.main} component="span" variant="caption">
            <Icon icon="flowbite:caret-up-solid" />
            {difference}
          </Typography>
        ) : (
          <Typography color={theme.palette.error.main} component="span" variant="caption">
            <Icon icon="flowbite:caret-down-solid" />
            {Math.abs(difference)}
          </Typography>
        )
      ) : (
        <Box width="18px" />
      )}
    </Stack>
  )
}

export default PriceLabel
