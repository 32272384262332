import { BasePaginationResponseV2, BaseResponse } from 'common/types/api'
import axios from 'core/api/axios'

import { buildUrlWithParams } from 'utils/url'

import { GetShrimpPriceParams, Region, ShrimpSize, ShrimpPriceGroup } from './types'

export const getShrimpPrices = (params: GetShrimpPriceParams) =>
  axios.get<BasePaginationResponseV2<ShrimpPriceGroup>>(
    buildUrlWithParams('/v2/shrimp-price/group', {
      ...params,
    })
  )

export const getRegions = () => axios.get<BaseResponse<Region[]>>('/v2/region')
export const getShrimpSizes = () => axios.get<BaseResponse<ShrimpSize[]>>('/v2/shrimp-size')
