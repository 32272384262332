import { forwardRef, useEffect, useCallback, ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'

// material
import { Box, BoxProps } from '@mui/material'

// utils
import track from 'utils/analytics'

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode
  title?: string
  description?: string
  keywords?: string
  image?: string
}

const TITLE = 'DoFarm บริหารจัดการฟาร์มอย่างมืออาชีพ'
const DESCRIPTION =
  'DoFarm ดูฟาร์ม บริหารจัดการฟาร์มกุ้งอย่างมืออาชีพ ช่วยเกษตรกรบริหารจัดการฟาร์มอย่างมีประสิทธิภาพ บันทึกข้อมูลและติดตามการดำเนินงานได้ตลอด 24 ชั่วโมง'
const KEYWORDS = 'ดูฟาร์ม,DoFarm,จดอาหารกุ้ง,บริหารจัดการฟาร์ม,วัดคุณภาพน้ำ,ราคากุ้งวันนี้,ราคากุ้ง'
const IMAGE = '/static/seo/cover.jpg'

const Page = forwardRef<HTMLDivElement, PageProps>(
  (
    {
      children,
      title = TITLE,
      description = DESCRIPTION,
      keywords = KEYWORDS,
      image = IMAGE,
      ...other
    },
    ref
  ) => {
    const { pathname } = useLocation()

    const sendPageViewEvent = useCallback(() => {
      track.pageview({
        page_path: pathname,
      })
    }, [])

    useEffect(() => {
      sendPageViewEvent()
    }, [sendPageViewEvent])

    return (
      <Box ref={ref} {...other}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="keywords" content={keywords} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
        </Helmet>
        {children}
      </Box>
    )
  }
)

export default Page
