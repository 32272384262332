import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import formatISO from 'date-fns/formatISO'
import getDate from 'date-fns/getDate'
import getHours from 'date-fns/getHours'
import getMinutes from 'date-fns/getMinutes'
import getMonth from 'date-fns/getMonth'
import getYear from 'date-fns/getYear'
import intlFormat from 'date-fns/intlFormat'
import isSameDay from 'date-fns/isSameDay'
import parseISO from 'date-fns/parseISO'

/**
 * return thai date in this format 9 มิถุนายน 2566
 * @param date
 * @returns string
 */
export const formatThaiDate = (date: string | Date | null) => {
  if (!date) return ''

  let givenDate = date
  if (typeof givenDate === 'string') {
    givenDate = parseISO(givenDate)
  }
  return intlFormat(
    givenDate,
    { day: 'numeric', month: 'long', year: 'numeric' },
    { locale: 'th-TH' }
  )
}

/**
 * Format date yyyy-mm-dd
 */
export const formatDate = (date: Date) => {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

/**
 * Format date dd/MM/yyyy HH:mm
 */
export const formatDateTime = (date: Date | string) => {
  if (!date) return ''

  let givenDate = date
  if (typeof givenDate === 'string') {
    givenDate = parseISO(givenDate)
  }
  
  return format(givenDate, 'dd/MM/yyyy HH:mm')
}

/**
 * Format date dd/mm/yyyy
 */
export const formatThaiDateNumeric = (date: Date | string | null) => {
  if (!date) return ''

  let givenDate = date
  if (typeof givenDate === 'string') {
    givenDate = parseISO(givenDate)
  }

  const d = new Date(givenDate)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [day, month, year].join('/')
}

export const getDateFromInputDateTime = (date: Date, time: Date) => {
  const day = getDate(date)
  const month = getMonth(date)
  const year = getYear(date)
  const hr = getHours(time)
  const minute = getMinutes(time)

  const dateTime = new Date(year, month, day, hr, minute)
  return dateTime
}

export const getMaxTimeFromInputDate = (date: Date) => {
  const now = new Date()
  const dateTime = getDateFromInputDateTime(date, now)
  if (isSameDay(dateTime, now)) {
    return now
  }
  return endOfDay(dateTime)
}

export const formatTime = (date: Date | string) => {
  if (!date) return ''

  let givenDate = date
  if (typeof givenDate === 'string') {
    givenDate = parseISO(givenDate)
  }
  return format(givenDate, 'HH:mm')
}

export const strToISOStr = (date: string) => {
  if (!date) return date

  const dateCls = new Date(date)

  const dateISO = parseISO(dateCls.toISOString())
  return formatISO(dateISO, { representation: 'complete' }).substring(0, 19) + 'Z'
}
