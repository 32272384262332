import { FC } from 'react'

// material
import TableCell from '@mui/material/TableCell'
import { useTheme } from '@mui/material/styles'

// components
import { Column, GroupColumn } from './types'

interface TableCellCustomProps {
  column?: Column
  groupColumns?: GroupColumn[]
  isPureHeader?: boolean
  manualWidth?: string
  manualColSpan?: number
  isGroupExpanded?: boolean
}

const TableCellCustom: FC<TableCellCustomProps> = ({
  column,
  groupColumns,
  isPureHeader = false,
  manualWidth,
  manualColSpan,
  children,
  isGroupExpanded,
}) => {
  const theme = useTheme()

  const align = column?.align ?? 'center'
  const width = manualWidth || (column && column.width)
  const colSpan = manualColSpan || (column && column.colSpan)
  const borderStyle = '1px solid #E5E7EB'

  return (
    <TableCell
      align={align}
      width={width}
      colSpan={colSpan}
      sx={{
        paddingY: theme.spacing(1),
        borderTop: borderStyle,
        borderBottom: isGroupExpanded ? 0 : borderStyle,
        '&:first-of-type': {
          boxShadow: 'none',
          paddingLeft: theme.spacing(column?.pl ?? 2),
          '&.MuiTableCell-head': {
            borderLeft: borderStyle,
            borderTopLeftRadius: groupColumns ? 0 : theme.shape.borderRadius,
            borderBottomLeftRadius: isGroupExpanded ? 0 : theme.shape.borderRadius,
          },
        },
        '&:last-of-type': {
          boxShadow: 'none',
          '&.MuiTableCell-head': {
            borderRight: borderStyle,
            borderTopRightRadius: groupColumns ? 0 : theme.shape.borderRadius,
            borderBottomRightRadius: isGroupExpanded ? 0 : theme.shape.borderRadius,
          },
        },
        '&.MuiTableCell-head': isPureHeader
          ? {
              backgroundColor: 'inherit',
              backgroundImage: 'none',
            }
          : {},
      }}
    >
      {children}
    </TableCell>
  )
}

export default TableCellCustom
