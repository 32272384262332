import { queryClient } from 'App'
import useClearRecoilState from 'common/hooks/useClearRecoilState'
import { useMutateLogout } from 'modules/auth/api/mutation'
import { enqueueSnackbar } from 'notistack'
import { useState, Fragment, ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { PATH_AUTH } from 'routes/paths'

import {
  Box,
  List,
  BoxProps,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material'
import { alpha, useTheme, styled } from '@mui/material/styles'

import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import { Icon } from '@iconify/react'

import getAccessToken from 'utils/getAccessToken'

import { MHidden } from './@material-extend'

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.caption,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  color: theme.palette.text.secondary,
}))

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ElementType<any> | undefined
  to?: string
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

// ----------------------------------------------------------------------

export type NavItemProps = {
  title: string
  path: string
  icon?: JSX.Element
  info?: JSX.Element
  children?: {
    title: string
    path: string
  }[]
}

function NavItem({ item, isShow }: { item: NavItemProps; isShow?: boolean | undefined }) {
  const theme = useTheme()
  const { pathname } = useLocation()
  const { title, path, icon, info, children } = item
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false

  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen(!open)
  }

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' },
  }

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item, i) => {
                const { title, path } = item
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false

                return (
                  <ListItemStyle
                    key={`${title}-${i}`}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: 'flex',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          bgcolor: 'text.disabled',
                          transition: (theme) => theme.transitions.create('transform'),
                          ...(isActiveSub && {
                            transform: 'scale(2)',
                            bgcolor: 'primary.main',
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemStyle>
                )
              })}
            </List>
          </Collapse>
        )}
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  )
}

const MobileNavList = () => {
  const theme = useTheme()
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const clearRecoilState = useClearRecoilState()
  const logout = useMutateLogout()

  const handleLogout = () => {
    clearRecoilState()
    queryClient.clear()
    localStorage.clear()
    navigate(PATH_AUTH.login)
  }

  const ABOUT_MENU = [
    {
      name: 'feedback',
      label: t('feedback'),
      icon: 'material-symbols:feedback',
      color: theme.palette.info.main,
      onClick: () => {},
    },
  ]

  const ACCOUNT_MENU = [
    {
      name: 'phone',
      label: t('phone'),
      icon: 'mdi:link-box-variant',
      onClick: () => {},
      color: theme.palette.primary.main,
    },
    {
      name: 'password',
      label: t('password'),
      icon: 'mdi:link-box-variant',
      onClick: () => {},
      color: theme.palette.primary.main,
    },
    {
      name: 'logout',
      label: t('logout'),
      icon: 'material-symbols:logout',
      onClick: handleLogout,
      color: theme.palette.error.main,
    },
  ]

  return (
    <>
      <ListSubheaderStyle>{t('about')}</ListSubheaderStyle>
      {ABOUT_MENU.map((el) => (
        <List key={el.name}>
          <ListItemStyle onClick={el.onClick}>
            <ListItemIconStyle>
              <Icon fontSize={24} icon={el.icon} color={el.color} />
            </ListItemIconStyle>
            <ListItemText disableTypography>{el.label}</ListItemText>
          </ListItemStyle>
        </List>
      ))}
      <ListSubheaderStyle>{t('account')}</ListSubheaderStyle>
      {ACCOUNT_MENU.map((el) => (
        <List key={el.name}>
          <ListItemStyle onClick={el.onClick}>
            <ListItemIconStyle>
              <Icon fontSize={24} icon={el.icon} color={el.color} />
            </ListItemIconStyle>
            <ListItemText disableTypography>{el.label}</ListItemText>
          </ListItemStyle>
        </List>
      ))}
    </>
  )
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined
  navConfig: {
    subheader: string
    items: NavItemProps[]
  }[]
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list, i) => {
        const { subheader, items } = list
        return (
          <Fragment key={`${subheader}-${i}`}>
            <List key={subheader} disablePadding>
              {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
              {items.map((item: NavItemProps, i) => (
                <NavItem key={item.title} item={item} isShow={isShow} />
              ))}
            </List>
            <MHidden width="smUp">
              <MobileNavList />
            </MHidden>
          </Fragment>
        )
      })}
    </Box>
  )
}
