import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'

export const createArrayOfNumber = (n: number): number[] => {
  return Array.from({ length: n }, (_, index) => index)
}

export const addNewElement = <T>(source: T[] | null | undefined, newElement: T | undefined) => {
  if (!newElement) return source

  const arr = source ? cloneDeep(source) : []
  arr.push(newElement)
  return arr
}

export const addNewElementAtFirstIdx = <T>(
  source: T[] | null | undefined,
  newElement: T | undefined
) => {
  if (!newElement) return source

  const arr = source ? cloneDeep(source) : []
  arr.unshift(newElement)
  return arr
}

export const removeElementById = <T extends { id: string }>(
  source: T[] | null | undefined,
  idToRemove: string
) => {
  const arr = source ? cloneDeep(source) : []
  return arr.filter((el) => el.id !== idToRemove)
}

interface EditProps<T> {
  source: T[] | null | undefined
  id: string
  newData: T
}

export const editElementById = <T extends { id: string }>({
  source,
  id,
  newData,
}: EditProps<T>): any[] => {
  const arr = source ? cloneDeep(source) : []
  const idx = findIndex(arr, (obj) => obj.id === id)
  arr.splice(idx, 1, newData)
  return arr
}

export const uniqueArray = <T>(a: T[], b: T[]) => {
  const uniqueA = a.filter((el) => b.indexOf(el) === -1)
  const uniqueB = b.filter((el) => a.indexOf(el) === -1)
  return uniqueA.concat(uniqueB)
}
