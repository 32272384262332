import { compact } from 'lodash'
import { useMemo } from 'react'

import { useGetShrimpPrices } from './queries'

export const useShrimpPrices = () => {
  const { data, ...rest } = useGetShrimpPrices()
  return useMemo(
    () => ({ ...rest, data: compact((data?.pages ?? []).map((page) => page.shrimpPrice).flat()) }),
    [data]
  )
}
