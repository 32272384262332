import { useTranslation } from 'react-i18next'
import { PATH_AUTH, ROOTS_PATH, ROOTS_SHRIMP_PRICE } from 'routes/paths'

import { Box, Button, Drawer, Link, Stack, useTheme } from '@mui/material'

interface NavDrawerProps {
  open: boolean
  onClose: () => void
}

const NavDrawer = ({ open, onClose }: NavDrawerProps) => {
  const theme = useTheme()
  const { t } = useTranslation('landing')

  return (
    <Drawer
      open={open}
      anchor="top"
      onClose={onClose}
      sx={{ [`.MuiBackdrop-root`]: { background: 'none' } }}
      PaperProps={{
        sx: {
          width: '100vw',
          boxShadow: 0,
          height: '100%',
        },
      }}
    >
      <Box height="10%" />
      <Box p={2}>
        <Stack spacing={2} alignItems="center">
          <Link
            href={ROOTS_PATH}
            underline="none"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
              },
              transition: 'all 0.3s',
            }}
            color="black"
          >
            {t('home.page')}
          </Link>
          <Link
            href={ROOTS_SHRIMP_PRICE}
            underline="none"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: theme.palette.primary.main,
              },
              transition: 'all 0.3s',
            }}
            color="black"
          >
            {t('shrimp.price.page')}
          </Link>
        </Stack>
        <Stack alignItems="center" spacing={2} mt={4}>
          <Button href={PATH_AUTH.login} variant="outlined" fullWidth>
            {t('login')}
          </Button>
          <Button href={PATH_AUTH.register} variant="contained" fullWidth>
            {t('free.register')}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default NavDrawer
