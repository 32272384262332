import { useState, SyntheticEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Autocomplete, AutocompleteInputChangeReason } from '@mui/material'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

import searchFill from '@iconify/icons-eva/search-fill'
import { Icon } from '@iconify/react'

interface SearchBoxProps {
  onChange: (searchWord: string) => void
  options: string[]
  value?: string
}

const SearchBox = ({ value, onChange, options }: SearchBoxProps) => {

  const [searchInput, setSearchInput] = useState('')
  const { t } = useTranslation('common')

  useEffect(() => {
    if (value) {
      setSearchInput(value)
    }
  }, [value])

  const handleAutocompleteChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string,
    _reason: AutocompleteInputChangeReason
  ) => {
    onChange(value)
    setSearchInput(value)
  }

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={options ?? []}
      fullWidth
      inputValue={searchInput}
      onInputChange={handleAutocompleteChange}
      renderInput={(params) => (
        <TextField
          {...params}
          name="name"
          placeholder={t('search')}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            startAdornment: (
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  width={20}
                  height={20}
                  color={'text.disabled'}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}

export default SearchBox
