import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { getShrimpPriceKey } from 'common/constants/queryKeys'
import { formatDate } from 'common/utils/date'
import { useRecoilValue } from 'recoil'

import { getRegions, getShrimpPrices, getShrimpSizes } from '.'
import { landingShrimpPriceDateRangeState, regionState } from '../stores/atom'

const ROWS_PER_PAGE = 10

export const useGetRegions = () => {
  return useQuery({
    queryKey: ['regions'],
    queryFn: async () => {
      const { data } = await getRegions()
      return data.data
    },
    staleTime: Infinity,
  })
}

export const useGetShrimpSizes = () => {
  return useQuery({
    queryKey: ['shrimp-sizes'],
    queryFn: async () => {
      const { data } = await getShrimpSizes()
      return data.data
    },
    staleTime: Infinity,
  })
}

export const useGetShrimpPrices = () => {
  const dateRange = useRecoilValue(landingShrimpPriceDateRangeState)
  const region = useRecoilValue(regionState)
  const startDate = formatDate(dateRange.startDate)
  const endDate = formatDate(dateRange.endDate)
  const initParams = {
    pageSize: ROWS_PER_PAGE,
    page: 1,
    startDate,
    endDate,
    regionId: region,
  }
  
  return useInfiniteQuery({
    queryKey: getShrimpPriceKey(startDate, endDate, region),
    queryFn: async ({ pageParam = initParams }) => {
      const { data } = await getShrimpPrices(pageParam)
      return {
        shrimpPrice: data.data?.shrimpPrices,
        nextPage: data.nextPage,
      }
    },
    getNextPageParam: (lastPage, _) => {
      if (lastPage.nextPage) {
        return { ...initParams, page: lastPage.nextPage }
      } else {
        return undefined
      }
    },
    staleTime: Infinity,
    enabled: !!region,
  })
}
