import useMedia from 'common/hooks/useMedia'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PATH_AUTH, ROOTS_PATH, ROOTS_SHRIMP_PRICE } from 'routes/paths'

import { Box, Button, Link, Stack, useTheme, IconButton } from '@mui/material'

import menuOutline from '@iconify/icons-eva/menu-outline'
import closeIcon from '@iconify/icons-iconamoon/close'
import { Icon } from '@iconify/react'

import { MHidden } from 'components/@material-extend'
import Logo from 'components/Logo'

import NavDrawer from '../NavDrawer'

interface GuestLayout {
  children: ReactNode
}

const GuestLayout = ({ children }: GuestLayout) => {
  const { t } = useTranslation('landing')

  const [open, setOpen] = useState(false)

  const isMobile = useMedia('md')
  const theme = useTheme()

  return (
    <Box width="100vw">
      <Stack
        width="100vw"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          background: 'white',
          px: isMobile ? 2 : 8,
          boxShadow: theme.shadows[10],
          zIndex: theme.zIndex.drawer + 1,
          position: 'relative',
        }}
      >
        <MHidden width="mdUp">
          <Link href={ROOTS_PATH} underline="none">
            <Logo />
          </Link>
        </MHidden>
        <MHidden width="mdDown">
          <Stack direction="row" spacing={2} alignItems="center">
            <Link href={ROOTS_PATH} underline="none">
              <Logo />
            </Link>
            <Link
              href={ROOTS_PATH}
              underline="none"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary.main,
                },
                transition: 'all 0.3s',
              }}
              color="black"
            >
              {t('home.page')}
            </Link>
            <Link
              href={ROOTS_SHRIMP_PRICE}
              underline="none"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  color: theme.palette.primary.main,
                },
                transition: 'all 0.3s',
              }}
              color="black"
            >
              {t('shrimp.price.page')}
            </Link>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link href={PATH_AUTH.login} underline="none" sx={{ cursor: 'pointer' }}>
              {t('login')}
            </Link>
            <Button href={PATH_AUTH.register} variant="contained">
              {t('free.register')}
            </Button>
          </Stack>
        </MHidden>
        <MHidden width="mdUp">
          <IconButton
            disableRipple
            onClick={() => setOpen(!open)}
            sx={{ color: theme.palette.grey[400] }}
          >
            <Icon icon={open ? closeIcon : menuOutline} />
          </IconButton>
        </MHidden>
      </Stack>
      <NavDrawer open={open} onClose={() => setOpen(false)} />
      {children}
    </Box>
  )
}

export default GuestLayout
