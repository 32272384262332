import { FC } from 'react';

// material
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(
  ({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    float: 'right',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  })
);

const ExpandComponent: FC<{ isGroupExpanded?: boolean; handleOnToggleExpand?: () => void }> = ({
  isGroupExpanded = true,
  handleOnToggleExpand 
}) => {
  return (
    <ExpandMore
      expand={isGroupExpanded}
      onClick={handleOnToggleExpand}
      aria-expanded={isGroupExpanded}
      aria-label="show more"
    >
      <ExpandMoreIcon />
    </ExpandMore>
  )
}

export default ExpandComponent