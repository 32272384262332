import { useState, UIEvent } from 'react'

interface useBasicTableProps {
  isLoading: boolean
  onLoading: () => void
}

const useInfiniteScrollTable = ({ isLoading, onLoading }: useBasicTableProps) => {
  const [isLastRowSeen, setIsLastRowSeen] = useState(false)

  return (e: UIEvent<HTMLDivElement>) => {
    // Check if the content is scrollable vertically
    const isScrollableVertically = e.currentTarget.scrollHeight > e.currentTarget.clientHeight

    if (!isScrollableVertically) {
      return
    }

    // Determine if the user has scrolled to the bottom
    const threshold = 1
    const bottom =
      e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
      e.currentTarget.scrollHeight - threshold

    if (bottom && !isLastRowSeen && !isLoading) {
      setIsLastRowSeen(true)
      onLoading()
    } else {
      setIsLastRowSeen(false)
    }
  }
}

export default useInfiniteScrollTable
